import React from "react";
import styled from "styled-components";
import bgWall from "../images/surfingbackground.jpg";
import { ProjectsDesc } from "../layout/Layout";

const Hero = () => {
  const first = `TOM`;
  const secondName = `ROLFE`;
  const subtitleFirstPart = `Junior Front-End Developer`;
  const subtitle = `HTML5 | CSS | JavaScript | React`;

  return (
    <>
      <HeroContainer>
        <TitleBox>
          <HeroTitle>
            {first}
            <TitleColor>{secondName}</TitleColor>
          </HeroTitle>
          <HeroSubtitle>
            <SubtitleColor>{subtitleFirstPart}</SubtitleColor>
            {subtitle}
          </HeroSubtitle>
          <HeroDesc></HeroDesc>
        </TitleBox>
      </HeroContainer>
    </>
  );
};

export default Hero;

const HeroContainer = styled.div`
  min-height: calc(100vh - 50px);
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)),
    url(${bgWall});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
`;

const TitleBox = styled.div`
  width: 100%;
  flex-direction: column;
  margin-top: 150px;
  /* justify-content: left; */
  /* padding-top: 180px;
  padding-left: 300px; */

  @media screen and (max-width: 860px) {
    /* margin: 30px;
    padding-top: 80px;
    padding-left: 30px; */
  }
`;

const HeroTitle = styled.div`
  font-weight: 700;
  font-size: 200px;
  text-align: center;
  white-space: pre-line;
  font-family: "Montserrat", sans-serif;
  line-height: 90%;
  margin-bottom: 30px;

  @media screen and (max-width: 860px) {
    font-size: 100px;
  }
`;

const TitleColor = styled.div`
  color: #1ab79c;
`;
const SubtitleColor = styled.div`
  color: white;
`;

const HeroSubtitle = styled.div`
  color: #1ab79c;
  font-weight: bold;
  font-size: 15.6px;

  text-align: center;
  white-space: pre-line;

  a {
    color: #1ab79c;
  }
`;

const HeroDesc = styled.div`
  font-weight: light;
  font-size: 13px;
  white-space: pre-line;
  /* background-color: yellow; */
`;
