import React from "react";
import styled from "styled-components";
import { useState } from "react";
import backgroundImage from "../images/portfolio.jpg";
import baliImg from "../images/bali.jpg";
import surfImg from "../images/surf.jpg";
import bjjImg from "../images/bjj.jpg";

import {
  AboutContainer,
  ProjectsTable,
  ProjectsRightSide,
  ProjectsUnder,
  ImgContainer,
  ImgBoxAbout,
  ImgOverlay,
  ImgTitle,
  ProjectsTitle,
  ProjectsSubtitle,
  ProjectsDesc,
  ProjectAlign,
} from "../layout/Layout";

const About = () => {
  const [mainImage, setMainImage] = useState(backgroundImage);
  const desc = `After 6 years working as a General Manager and Director in the events industry, running some of the largest bars at the world’s biggest festivals, I became tired of needing an efficient recruitment platform, so decided to do something about it. What started as an idea with a friend has now become a fully functioning app and web platform, with over 4000 users. 
    
  The process really ignited my interest in the development process and so I began my own journey into learning to code.`;

  const surf = "Surfing";
  const bali = "Bali";
  const bjj = "Brazilian Jiu Jitsu";

  function setProject1() {
    setMainImage(baliImg);
  }

  function setProject2() {
    setMainImage(surfImg);
  }

  function setProject3() {
    setMainImage(bjjImg);
  }

  return (
    <>
      <AboutContainer>
        <ProjectsTable>
          <ProjectAlign>
            {/* <ProjectsImage> */}
            <MainImg>
              <img src={mainImage} alt='alfresco' />
            </MainImg>
            {/* </ProjectsImage> */}
            <ProjectsRightSide>
              <ProjectsTitle>About me</ProjectsTitle>
              <ProjectsSubtitle>
                Interests: Dogs, Surfing, Jiu-Jitsu
              </ProjectsSubtitle>
              <ProjectsDesc>{desc}</ProjectsDesc>
            </ProjectsRightSide>
          </ProjectAlign>
          <ProjectsUnder>
            <ImgContainer>
              <ImgBoxAbout
                onClick={() => {
                  setProject1();
                }}
              >
                <Img>
                  <img src={baliImg} alt={bali} />
                </Img>
                <ImgOverlay>
                  <ImgTitle>{bali}</ImgTitle>
                </ImgOverlay>
              </ImgBoxAbout>

              <ImgBoxAbout
                onClick={() => {
                  setProject2();
                }}
              >
                <Img>
                  <img src={surfImg} alt={surf} />
                </Img>
                <ImgOverlay>
                  <ImgTitle>{surf}</ImgTitle>
                </ImgOverlay>
              </ImgBoxAbout>

              <ImgBoxAbout
                onClick={() => {
                  setProject3();
                }}
              >
                <Img>
                  <img src={bjjImg} alt={bjj} />
                </Img>
                <ImgOverlay>
                  <ImgTitle>{bjj}</ImgTitle>
                </ImgOverlay>
              </ImgBoxAbout>
            </ImgContainer>
          </ProjectsUnder>
        </ProjectsTable>
      </AboutContainer>
    </>
  );
};

export default About;

const MainImg = styled.div`
  /* background-color: purple; */
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    border-right-style: solid;
    border-width: 5px;
    border-color: #1ab79c;
    max-width: 500px;
    @media screen and (max-width: 860px) {
      width: 100vw;
      border-right-style: none;
      border-bottom-style: solid;
    }
  }
`;

const Img = styled.div`
  img {
    width: 100%;
    border-radius: 5%;
    max-height: 300px;
  }
`;
