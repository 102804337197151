import impact from "../images/impact.png";
import charlotte from "../images/charlotte.png";
import eventmaster from "../images/eventmaster.png";
import linkedin from "../images/linkedin.png";
import wordpress from "../images/icons/wordpress.png";
import css from "../images/icons/css3.png";
import github from "../images/icons/github.png";
import html5 from "../images/icons/html5.png";
import react from "../images/icons/react.png";
import js from "../images/icons/square-js.png";
import clearHead from "../images/clearhead.png";
import gymWebsite from "../images/gym-website.png";

const ProjectsData = [
  {
    title: "Event Master",
    desc: `Event staff management software. We are a small start up built out of my experience working at multiple leading UK music festivals. I saw that there was a gap in the market for a good quality and useful recruitment platform that combined staff recruitment, rotas and processing payroll  specifically for pop up event companies. 
    
    I set up the company with a software developer and I led the process leading to the final go live with real customers. During this time I led strategy workshops, market research, user testing and once we had a working prototype we began pitching to potential clients. We now have over 4000 users and continue to improve our product as we learn more from our users.
    `,
    projectNo: "1",
    img: eventmaster,
    link: "https://eventmaster.jobs/",
    icon: <></>,
  },
  {
    title: "Clear My Head",
    desc: "Created as a database of venues that serve the non alcoholic drink, 'Clear head'. Using Firebase to give users the ability to easily add to the database, view and search by City. After I stopped drinking and found 'Clear Head', I thought it would make a fun and useful project.",
    projectNo: "2",
    img: clearHead,
    link: "https://tomrolfe20.github.io/clear-my-head",
    icon: (
      <>
        <img src={html5} alt='html5' />
        <img src={css} alt='css' />
        <img src={js} alt='js' />
        <img src={github} alt='github' />
        <img src={react} alt='react' />
      </>
    ),
    iconDesc: "Html, CSS, Javascript, Github, React",
  },
  {
    title: "Impact Bars",
    desc: "Made in React and written from scratch. A simple website, attractive, functional and responsive. This was one of my first projects, using styled-components for the first time. Hosted on Github.",
    projectNo: "3",
    img: impact,
    link: "https://tomrolfe20.github.io/impactbarswebsite/",
    icon: (
      <>
        <img src={html5} alt='html5' />
        <img src={css} alt='css' />
        <img src={js} alt='js' />
        <img src={github} alt='github' />
        <img src={react} alt='react' />
      </>
    ),
    iconDesc: "Html, CSS, Javascript, Github, React",
  },
  {
    title: "Charlotte-Writes",
    desc: "Made in Word Press with Elementor. This website was designed for Charlotte, a travel writer for the National Geographic who wanted to be able to update her blog and post her articles on her laptop with ease from her travels.",
    projectNo: "4",
    img: charlotte,
    link: "https://charlotte-writes.co.uk/",
    icon: (
      <>
        <img src={wordpress} alt='wordpress' />
      </>
    ),
    iconDesc: "Wordpress, Elementor",
  },
  {
    title: "LinkedIn Clone",
    desc: "Created as part of a tutorial using Firebase to create the database and Google Auth to log in. Including features to upload images/videos and create and edit posts. This was quite a complicated build and I learnt alot from it",
    projectNo: "2",
    img: linkedin,
    link: "https://linkedin-clone-b4f86.web.app/",
    icon: (
      <>
        <img src={html5} alt='html5' />
        <img src={css} alt='css' />
        <img src={js} alt='js' />
        <img src={github} alt='github' />
        <img src={react} alt='react' />
      </>
    ),
    iconDesc: "Html, CSS, Javascript, Github, React",
  },
  {
    title: "The Fit Club",
    desc: "Created as part of a tutorial using React, Javascript and CSS to create a responsive Gym website.",
    projectNo: "2",
    img: gymWebsite,
    link: "https://tomrolfe20.github.io/gym-website",
    icon: (
      <>
        <img src={html5} alt='html5' />
        <img src={css} alt='css' />
        <img src={js} alt='js' />
        <img src={github} alt='github' />
        <img src={react} alt='react' />
      </>
    ),
    iconDesc: "Html, CSS, Javascript, Github, React",
  },
];

export default ProjectsData;
