import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    body {
    margin: 0;
    padding: 0;
    height: 100%;
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Montserrat', sans-serif;
    background-color: black;
    color: white;
    scroll-behavior: smooth;
  }
`;

export default GlobalStyle;
