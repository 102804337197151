import React from "react";
import styled from "styled-components";
import { useState } from "react";
import backgroundImage from "../images/default.png";
import impact from "../images/impact.png";
import charlotte from "../images/charlotte.png";
import eventmaster from "../images/eventmaster.png";
import linkedin from "../images/linkedin.png";
import wordpress from "../images/icons/wordpress.png";
import css from "../images/icons/css3.png";
import github from "../images/icons/github.png";
import html5 from "../images/icons/html5.png";
import react from "../images/icons/react.png";
import js from "../images/icons/square-js.png";

import {
  ProjectsContainer,
  ProjectsTable,
  ProjectsImage,
  Img,
  ProjectsRightSide,
  ProjectsUnder,
  ImgContainer,
  ImgBoxProjects,
  ImgOverlay,
  ImgTitle,
  ProjectsTitle,
  ProjectsSubtitle,
  ProjectsDesc,
  ProjectsIcons,
  ProjectsIconsDesc,
  ProjectsLinks,
  ProjectAlign,
} from "../layout/Layout";

const Projects = ({ data }) => {
  const [mainImage, setMainImage] = useState(backgroundImage);
  const [title, setTitle] = useState("Projects");
  const [desc, setDesc] = useState(
    `For 19 months, I've been working through a multitude of online tutorials and completing all of the relevant front end Codecademy courses including advanced Javascript and React.

    I have vast experience with managing teams and problem solving in high pressure environments and I have learned to excel in these situations. I am ready for the next step in my career as a Front End Web Developer. I feel I will make a particularly hard working addition to any development or project team with an enthusiasm for problem solving and learning from my peers.

    Here are some of the projects i've been working on so far. 
    
    Click the images below for more info.
    `
  );
  const [icon, setIcon] = useState();
  const [iconDesc, setIconDesc] = useState();
  const [link, setLink] = useState();

  return (
    <>
      <ProjectsContainer>
        <ProjectsTable>
          <ProjectAlign>
            {/* <ProjectsImage> */}
            <MainImg>
              <img src={mainImage} alt='alfresco' />
            </MainImg>
            {/* </ProjectsImage> */}
            <ProjectsRightSide>
              <ProjectsTitle>{title}</ProjectsTitle>
              <ProjectsDesc>{desc}</ProjectsDesc>
              <ProjectsLinks>
                <a href={link} target='_blank' rel='noreferrer'>
                  {link ? "Check it out here" : ""}
                </a>
              </ProjectsLinks>
              <ProjectsIcons>{icon}</ProjectsIcons>
              <ProjectsIconsDesc>{iconDesc}</ProjectsIconsDesc>
            </ProjectsRightSide>
          </ProjectAlign>
          <ProjectsUnder>
            <ImgContainer>
              {data.map((project, index) => {
                return (
                  <ImgBoxProjects
                    onClick={() => {
                      setTitle(`${project.title}`);
                      setMainImage(project.img);
                      setDesc(`${project.desc}`);
                      setIcon(project.icon);
                      setIconDesc(project.iconDesc);
                      setLink(project.link);
                      window.scrollTo(0, 0);
                    }}
                  >
                    <Img>
                      <img src={project.img} alt={project.title} />
                    </Img>
                    <ImgOverlay>
                      <ImgTitle>{project.title}</ImgTitle>
                    </ImgOverlay>
                  </ImgBoxProjects>
                );
              })}
            </ImgContainer>
          </ProjectsUnder>
        </ProjectsTable>
      </ProjectsContainer>
    </>
  );
};

export default Projects;

const MainImg = styled.div`
  /* background-color: purple; */
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 323px;
  img {
    border-right-style: solid;
    border-width: 5px;
    border-color: #1ab79c;
    max-width: 500px;
    @media screen and (max-width: 860px) {
      width: 100vw;
      border-right-style: none;
      border-bottom-style: solid;
    }
  }
`;
