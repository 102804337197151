import React from "react";
import {
  ProjectsContainer,
  TitleBox,
  ProjectsTitle,
  ProjectsSubtitle,
  ProjectsDesc,
  ProjectsIcons,
  ProjectsLinks,
} from "../layout/Layout";
import linkedin from "../images/icons/linkedin.png";

const Contact = () => {
  return (
    <>
      <ProjectsContainer>
        <TitleBox>
          <ProjectsTitle>Tom Rolfe</ProjectsTitle>
          <ProjectsSubtitle>
            <a href='mailto: tomrolfe20@gmail.com'>tomrolfe20@gmail.com</a>
            <br />
            <br />
            <a href='https://github.com/tomrolfe20'>Github</a>
          </ProjectsSubtitle>
          <ProjectsIcons>
            <a
              href='https://www.linkedin.com/in/tom-rolfe-3287361a4/'
              target='_blank'
              rel='noreferrer'
            >
              <img src={linkedin} alt='Linkedin' />
            </a>
          </ProjectsIcons>
          <ProjectsDesc></ProjectsDesc>
        </TitleBox>
      </ProjectsContainer>
    </>
  );
};

export default Contact;
